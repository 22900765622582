import { Card, Col, Container, Row } from 'react-bootstrap'
import { t } from '../locale'
import 'bootstrap/dist/css/bootstrap.min.css'
import "bootstrap/dist/js/bootstrap.bundle.min.js"

import { CheckoutForm } from '../shared/StripeEmbeddedForm';

import React, { useState } from "react";
import { loadStripe } from "@stripe/stripe-js";

function Index() {

    const stripeApiKey = process.env.REACT_APP_STRIPE_PUBLIC;

    if (!stripeApiKey) {
        throw new Error('Stripe public API key is not defined in environment variables');
    }

    const [paymentCompleted, setPaymentCompleted] = useState(false);

    const stripePromise = loadStripe(stripeApiKey);

    // const [hasAccess, setHasAccess] = useState(false);
    // const [loading, setLoading] = useState(true);

    // useEffect(() => {
    const pathSegments = window.location.pathname.split('/');
    const companyId = pathSegments[1];
    const requestId = pathSegments[2];

    checkRequest(companyId, requestId)
        .then(hasAccess => {
            // setHasAccess(hasAccess);
            // setLoading(false);
        })
        .catch(error => {
            console.error("Error in checkRequest:", error);
            // setLoading(false);
        });
    // }, []);

    const [checked, setChecked] = React.useState(false);

    const createJob = async () => {
        await fetch(`${process.env.REACT_APP_API_URL}/api/v1/request/update/paymentMethod`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                companyId: companyId,
                requestId: requestId,
                paymentMethod: "direct"

            })
        })

        await fetch(`${process.env.REACT_APP_API_URL}/api/v1/job`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                companyId: companyId,
                requestId: requestId,
                paymentMethod: "direct"
            })
        })
    }

    return (

        <div className="section-container">
            <div className="section">
                <Card.Body>
                    <Container>
                        <div className='App'>
                            <CheckoutForm />
                        </div>
                    </Container>
                </Card.Body>
            </div>
        </div>

    )
}

async function checkRequest(companyId: string, requestId: string): Promise<boolean> {
    try {
        const response = await fetch(process.env.REACT_APP_API_URL + "/api/v1/request/data", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ requestId, companyId })
        });
        return response.status === 404 ? false : true;
    } catch (error) {
        // Handle any errors, such as network issues or server errors
        console.error("Error while checking request:", error);
        return false;
    }
}

export default Index
