import { Card, Container, NavDropdown, Navbar } from 'react-bootstrap'
import { Outlet } from 'react-router-dom'
import { Translate } from 'react-bootstrap-icons'
import { changeLocale, locales, getLocale } from './locale'
import KAlert from './components/KAlert'
import { useState } from 'react'
import { t } from "./locale";

function Layout() {

    const [kAlert, setKAlert] = useState(window.kAlert)
    const [showKAlert, setShowKAlert] = useState(false)
    const currentLocale: string = getLocale()
    function handleLocaleChange(locale: string) {
        locale !== currentLocale && changeLocale(locale)
    }

    return (
        <>
            <Card className='border-0'>
                <Card.Header className='border-bottom py-1'>
                    <Container className='d-flex align-items-center'>
                        <img alt='logo' src='/logo.png' className='p-2' /> <h4 className='mx-3 d-inline'>{t("videoIdentification")}</h4>
                        <Navbar className='ms-auto'>
                            <NavDropdown title={<><div className='me-1' /><img
                                                src={`/flags/${currentLocale}.png`}
                                                alt={`${currentLocale} flag`}
                                                style={{ width: '17px', height: '13px', marginRight: '2px' }}
                                            /> {(locales as any)[currentLocale]}</>}>
                                {
                                    Object.entries(locales).map(([locale, language]) => (
                                        <NavDropdown.Item className='mr-2' key={locale}
                                            onClick={() => { handleLocaleChange(locale) }}>
                                            <img
                                                src={`/flags/${locale}.png`}
                                                alt={`${language} flag`}
                                                style={{ width: '17px', height: '13px', marginRight: '4px' }}
                                            />
                                            {language}
                                        </NavDropdown.Item>
                                    ))
                                }
                            </NavDropdown>
                        </Navbar>
                    </Container>
                </Card.Header>

                <Card.Body>
                    <Container>
                        <KAlert show={showKAlert}
                            dissmisable={true}
                            variant={kAlert?.variant}
                            message={kAlert?.message} />

                        <Outlet />
                    </Container>
                </Card.Body>
            </Card>

            <footer className='fixed-bottom border-top bg-light'>
                <Container className='d-flex justify-content-between py-1'>
                    <small className='text-muted'>
                        © {new Date().getFullYear()} DATA Security GmbH. Alle Rechte vorbehalten.
                    </small>
                    <div className='d-flex ms-auto'>
                        <small className="me-3">
                            <a href="https://data-security.one/impressum/" className="text-secondary text-decoration-none fw-bold">Impressum</a>
                        </small>
                        <small>
                            <a href="https://data-security.one/datenschutzhinweise/" className="text-secondary text-decoration-none fw-bold">Datenschutz</a>
                        </small>
                    </div>
                </Container>
            </footer>
        </>
    )
}

export default Layout