import { useState, useEffect } from "react";
import { t } from "../locale";
import { Alert, Container } from "react-bootstrap";
import { createJob, getReferenceNumber } from "../helpers/helpers";


function Payment() {
    const [expiredPage, setExpiredPage] = useState(false);
    const [hasAccess, setHasAccess] = useState(true);
    const [referenceNumber, setReferenceNumber] = useState('');
    const [jobCreated, setJobCreated] = useState(false);

    const pathSegments = window.location.pathname.split("/");
    const companyId = pathSegments[1];
    const requestId = pathSegments[2];
    

    useEffect(() => {
        fetchData();
        fetchRefNumber();
    }, []);

    useEffect(() => {
        

    }, [companyId, requestId]);

    const fetchRefNumber = async () => {
        const refNumber = await getReferenceNumber(companyId, requestId);
        setReferenceNumber(refNumber);
    }
    
    const fetchData = async () => {
        try {
            const response = await fetch(
                `${process.env.REACT_APP_API_URL}/api/v1/request/data/all`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        companyId: companyId,
                        requestId: requestId,
                    }),
                }
            );
            if (!response.ok) {
                throw new Error("Failed to fetch data");
            }

            const data = await response.json();

            if (data) {
                if (data.status === "EXPIRED") {
                    setExpiredPage(false);
                }
            }
        } catch (error) {
            console.error("Error fetching data:", error);
            setHasAccess(false);
        }
    };

    return (
        <>
            <div hidden={expiredPage}>
                <div id="paymentInstructions" className="py-3">
                    {hasAccess ? (
                        <Container>
                            <h4 className="text-primary">{t("paymentInstructions")}</h4>
                            <div className="mt-4"></div>
                            {t("paymentStep1")}
                            <div className="mt-2"></div>
                            {t("paymentStep2")}
                            <div className="mt-2"></div>
                            {t("paymentStep3")}
                            <div className="mt-2"></div>
                            <span className="fw-bold">{t('achtung')}:</span> {t("paymentStep4")}
                            <div className="mt-2"></div>
                            <span className="fw-bold">{t("paymentStep5")}</span>
                            <div className="mt-2"></div>
                            {t("paymentStep6")}
                            <div className="mt-2"></div>
                            {t("paymentStep7")}
                            <div className="mt-2"></div>
                            {t("paymentStep8")} {referenceNumber}
                        </Container>
                    ) : (
                        <Alert variant="danger" className="mt-3">
                            <div>{t("accessDenied")}</div>
                        </Alert>
                    )}
                </div>
            </div>
            <div className="py-2" hidden={!expiredPage}>
                <h4>{t("requestExpired")}</h4>
                <p>{t("accessExpired")}</p>
            </div>
        </>
    );
}

export default Payment;