import { Alert, Button, Card, Col, Container, Row } from "react-bootstrap";
import { useRef, useState, useEffect } from "react";
import { t } from "../locale";
import {
  captureSnapshot,
  CameraComponent,
  imageComparing,
  checkDocumentData,
  saveImage,
  createJob
} from "../helpers/helpers";
import 'bootstrap-icons/font/bootstrap-icons.css';
import { useNavigate } from "react-router-dom";

let selfImageData: string;
let documentImageData: string;
let documentImageDataDocumentAnalyze: string;
let countDocCheck = 0;
let countSelfCheck = 0;

function Index() {
  const [selfIdentify, setSelfIdentify] = useState(true);
  const [hasAccess, setHasAccess] = useState(true);
  const [expiredPage, setExpiredPage] = useState(false);
  const [facingMode, setFacingMode] = useState('environment');
  const [successAlert, setSuccessAlert] = useState(true);
  const [errorAlert, setErrorAlert] = useState(true);
  const [errorAlertMessage, setErrorAlertMessage] = useState('');
  const [isMobile, setIsMobile] = useState(false);
  const [borderColor, setBorderColor] = useState('border-light'); // Initial gray color
  const [secondsLeft, setSecondsLeft] = useState(10); // Initialize with 25 seconds
  const [documentChecked, setDocumentChecked] = useState(false);
  const [textLabel, setTextLabel] = useState(t('holdDocument'));
  const [checkInProgress, setCekInProgress] = useState(false);
  const [similarityChecked, setSimilarityChecked] = useState(false);
  const [startProcessButton, setStartProcessButton] = useState(true);
  const [alertWait, setAlertWait] = useState(true);
  const [referenceNumber, setReferenceNumber] = useState('');
  const [base64DocumentImage, setBase64DocumentImage] = useState<string | null>(null); // State for document image
  const [base64SelfImage, setBase64SelfImage] = useState<string | null>(null); // State for self image
  const fileInputRefDocument = useRef<HTMLInputElement | null>(null);
  const fileInputRefSelf = useRef<HTMLInputElement | null>(null);
  const navigate = useNavigate();

  const bottomRef = useRef<HTMLDivElement>(null);

  const pathSegments = window.location.pathname.split("/");
  const companyId = pathSegments[1];
  const requestId = pathSegments[2];

  const handleButtonDocumentUpload = () => {
    if (fileInputRefDocument.current) {
      fileInputRefDocument.current.click(); // Trigger the file input click
    }
  };

  const handleButtonSelfUpload = () => {
    if (fileInputRefSelf.current) {
      fileInputRefSelf.current.click(); // Trigger the file input click
    }
  };

  const handleDocumentUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]; // Check if a file is selected
    if (file) {
      // base64 without resizing for Microsoft Dozument Analyze
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result as string;
        documentImageDataDocumentAnalyze = base64String.replace(/^data:image\/(jpeg|png);base64,/, '');
      };
      reader.readAsDataURL(file); // Convert image to Base64
      //

      resizeImage(file, 800, 600, (resizedBase64) => {
        const base64String = resizedBase64.replace(/^data:image\/(jpeg|png);base64,/, '');
        console.log("BASE: " + base64String);
        documentImageData = base64String;
        setBase64DocumentImage(base64String); // Setuješ smanjenu sliku u Base64 formatu

        const capturedDocImageElement = document.getElementById("capturedImageDocument") as HTMLImageElement;
        if (capturedDocImageElement) {
          capturedDocImageElement.src = "data:image/png;base64," + resizedBase64; // Postavi src za prikaz smanjene slike

          capturedDocImageElement.style.height = '200px';
        }
      });
    }
    scrollToBottom();
  };

  const handleSelfUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]; // Check if a file is selected
    if (file) {
      resizeImage(file, 800, 600, (resizedBase64) => {
        const base64String = resizedBase64.replace(/^data:image\/(jpeg|png);base64,/, '');
        selfImageData = base64String;
        setBase64DocumentImage(base64String); // Setuješ smanjenu sliku u Base64 formatu

        const capturedImageSelfElement = document.getElementById("capturedImageSelf") as HTMLImageElement;
        if (capturedImageSelfElement) {
          capturedImageSelfElement.src = "data:image/png;base64," + resizedBase64; // Postavi src za prikaz smanjene slike
          capturedImageSelfElement.style.height = '200px';
        }
      });
    }
    scrollToBottom();
    setAlertWait(false);
    startProcess();
  };

  const resizeImage = (file: File, maxWidth: number, maxHeight: number, callback: (base64: string) => void) => {
    const reader = new FileReader();

    reader.onloadend = () => {
      const img = new Image();
      img.src = reader.result as string;

      img.onload = () => {
        const canvas = document.createElement('canvas');
        let width = img.width;
        let height = img.height;

        // Proveri proporcije i prilagodi veličinu
        if (width > height) {
          if (width > maxWidth) {
            height *= maxWidth / width;
            width = maxWidth;
          }
        } else {
          if (height > maxHeight) {
            width *= maxHeight / height;
            height = maxHeight;
          }
        }

        // Postavi canvas dimenzije
        canvas.width = width;
        canvas.height = height;

        // Iscrtaj sliku na canvas sa smanjenom rezolucijom
        const ctx = canvas.getContext('2d');
        if (ctx) {
          ctx.drawImage(img, 0, 0, width, height);

          // Konvertuj canvas nazad u Base64 format
          const base64String = canvas.toDataURL('image/png'); // Može biti i 'image/png'
          const imageData = base64String.replace(/^data:image\/png;base64,/, '');
          callback(imageData);
        }
      };
    };

    reader.readAsDataURL(file);
  };



  const handleDeviceDetection = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    console.log(userAgent);
    const isMobile = /iphone|ipad|ipod|android|blackberry|windows phone/g.test(userAgent);
    const isTablet = /(ipad|macintosh|tablet|playbook|silk)|(android(?!.*mobile))/g.test(userAgent);

    if (isMobile) {
      setIsMobile(true);
    } else if (isTablet) {
      setIsMobile(true)
    }
  }

  CameraComponent(facingMode, isMobile);

  const handleCloseAlert = () => {
    setErrorAlert(true);
  };


  function scrollToBottom() {
    setTimeout(() => {
      window.scrollTo(0, document.body.scrollHeight);
    }, 0);
  }

  useEffect(() => {
    fetchData();
    handleDeviceDetection();

  }, []);

  const changeCamera = () => {
    setFacingMode((prevMode) => (prevMode === "user" ? "environment" : "user"));
  }

  const fetchData = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/request/data/all`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            companyId: companyId,
            requestId: requestId,
          }),
        }
      );
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const data = await response.json();

      if (data) {
        if (data.status === "EXPIRED") {
          setExpiredPage(false);
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setHasAccess(false);
    }
  };

  const init = () => {
    setFacingMode('environment')
    setCekInProgress(false);
    setTextLabel(t('holdDocument'));
    setDocumentChecked(false);
    setSimilarityChecked(false);
    setSecondsLeft(10);
  }

  const initCapturingFace = () => {
    setTimeout(async () => {
      setCekInProgress(false);
      setTextLabel(t('holdFace'));
      setDocumentChecked(true);
      setSecondsLeft(10);
      startCaptureFace();
    }, 10000);
  }

  const startProcess = async () => {
    // navigate(`/${companyId}/${requestId}/video`);
    countDocCheck++;

    const video = document.getElementById("videoElement") as HTMLVideoElement;
    let capturedDocImageElement: HTMLImageElement | null = null;

    if (!isMobile) {
      const countdown = setInterval(() => {
        setSecondsLeft(prevSeconds => {
          if (prevSeconds <= 1) {
            clearInterval(countdown); // Change border color to green when countdown ends
            return 0;
          }
          return prevSeconds - 1;
        });
      }, 1000);
    }

    setTimeout(async () => {
      if (!isMobile) {
        const result = await captureSnapshot(
          video,
          "DOCUMENT",
          selfImageData,
          documentImageData
        );

        setTextLabel(t('loading'))
        setCekInProgress(true);
        scrollToBottom();

        documentImageData = result.documentImageData; // Ensure documentImageData is updated
        if (!capturedDocImageElement) {
          capturedDocImageElement = document.getElementById("capturedImageDocument") as HTMLImageElement;
        }
        if (capturedDocImageElement) {
          capturedDocImageElement.src = "data:image/png;base64," + documentImageData;
        }
      }

      let data;
      if (isMobile) {
        data = await checkDocumentData(documentImageDataDocumentAnalyze, requestId, companyId);
      } else {
        data = await checkDocumentData(documentImageData, requestId, companyId);
      }

      if (data.validation === true) {
        setBorderColor('border-success');
        setTextLabel(t('wait'));
        changeCamera();

        initCapturingFace();
      } else {
        setTextLabel('Failed request');
        if (countDocCheck === 2) {
          setStartProcessButton(false);
          setAlertWait(true);
          setErrorAlert(false);
          setSecondsLeft(0);
          if (isMobile) {
            setErrorAlertMessage('Identifikacija nije uspela. Probajte ponovo.')

          } else {
            setErrorAlertMessage('Prebacite se na telefon, kako bi napravili kvalitetnije slike.');
          }
          return;
        } else {
          setAlertWait(true);
          setErrorAlert(false);
          setErrorAlertMessage(t("identificationFailed"));
          setTimeout(() => {
            init();
          }, 5000);
        }
      }

    }, 10000);
  }

  const startCaptureFace = async () => {
    countSelfCheck++;
    if (countSelfCheck > 2) {
      if (isMobile) {
        setErrorAlertMessage('Identifikacija nije uspela. Probajte ponovo.')
        setAlertWait(true);
      } else {
        setErrorAlertMessage('Prebacite se na telefon, kako bi napravili kvalitetnije slike.');
      }
      
      setErrorAlert(false);
      return;
    }
    const video = document.getElementById("videoElement") as HTMLVideoElement;
    let capturedImageSelfElement: HTMLImageElement | null = null;

    if (!isMobile) {
      const countdown = setInterval(() => {
        setSecondsLeft(prevSeconds => {
          if (prevSeconds <= 1) {
            clearInterval(countdown); // Change border color to green when countdown ends
            return 0;
          }
          return prevSeconds - 1;
        });
      }, 1000);
    }

    setTimeout(async () => {
      if (!isMobile) {
        const result = await captureSnapshot(
          video,
          "SELF",
          selfImageData,
          documentImageData
        );

        setTextLabel(t('loading'));
        setCekInProgress(true);

        selfImageData = result.selfImageData; // Ensure documentImageData is updated
        if (!capturedImageSelfElement) {
          capturedImageSelfElement = document.getElementById("capturedImageSelf") as HTMLImageElement;
        }
        if (capturedImageSelfElement) {
          capturedImageSelfElement.src = "data:image/png;base64," + selfImageData;
        }
      }

      const data = await imageComparing(documentImageData, selfImageData, companyId, requestId);

      if (data.status === true) {
        setSimilarityChecked(true);
        setBorderColor('border-success');
        scrollToBottom();
        setTextLabel('Finished');
        setAlertWait(true);
        setSuccessAlert(false);

        setTimeout(async () => {
          setCekInProgress(false);
          setAlertWait(true);
          setSelfIdentify(false);
          createJob(companyId, requestId, setReferenceNumber);
          navigate(`/${companyId}/${requestId}/video`);
        }, 5000);
      } else {
        setTextLabel('Capture face again.');
        setAlertWait(true);
        setErrorAlert(false);
        setErrorAlertMessage('Identifikacija nije uspela. Probajte ponovo.')
        scrollToBottom();
        setTimeout(() => {
          initCapturingFace();
        }, 5000);
      }

    }, 10000);
  }


  return (
    <>
      <div hidden={expiredPage}>
        <div hidden={!selfIdentify}>
          {hasAccess ? (
            <>
              <h4 className="my-3 text-primary">
                {selfIdentify
                  ? t("selfIdentifactionStep")
                  : t("paymentStep")}
              </h4>
              <Container>
                {t("tipSentence1")}
                <div className="mt-2"></div>
                <div className="mt-1">
                  <span className="fw-bold">{t('schritt1')}</span>
                  {t("tipSentence2")}
                </div>
                <span className="fw-bold">{t("tipSentence3")}</span>

                <ul>
                  <li>{t("tipSentence4")}</li>
                  <li>{t("tipSentence5")}</li>
                  <li>{t("tipSentence6")}</li>
                  <li>{t("tipSentence7")}</li>
                  <li>{t("tipSentence8")}</li>
                  <li>{t("tipSentence9")}</li>
                  <li>{t("tipSentence10")} <span className="fw-bold">{t("tipSentence11")}</span> {t("tipSentence12")}</li>
                  <li>{t("tipSentence13")}</li>
                  <li>{t("tipSentence14")}</li>
                </ul>

                <span className="fw-bold mt-2">{t('schritt2')}</span> {t("tipSentence15")}
                <div className="mt-2"></div>
                <span className="fw-bold mt-2 mb-2">{t('schritt3')}</span> {t("tipSentence16")}
                <div className="mt-3"></div>
              </Container>
              <Container className="mb-5">
                <Row>
                  <Col sm={6} className="pl-2 pt-2">
                    {!isMobile &&
                      <div id="videoContainer" className="position-relative">
                        <video className="position-relative rounded w-100" id="videoElement" autoPlay muted></video>
                        <div className="position-absolute top-0 start-50 translate-middle-x d-flex justify-content-center text-white mt-2">
                          {!isMobile && <span className="me-2 text-nowrap">{textLabel}</span>}
                          {isMobile && <span className="me-2 small text-nowrap">{textLabel}</span>}
                          {secondsLeft !== 0 && <span>{secondsLeft}</span>}
                        </div>
                        {checkInProgress && (
                          <div
                            id="documentFrame"
                            className={`position-absolute top-50 start-50 translate-middle rounded-3 border ${borderColor} d-flex justify-content-center`}
                            style={{ width: '75%', height: '80%' }}
                          >
                          </div>
                        )}
                        {!documentChecked && !checkInProgress && (
                          <div
                            id="documentFrame"
                            className={`position-absolute top-50 start-50 translate-middle rounded-3 border ${borderColor} d-flex justify-content-center`}
                            style={{ width: '75%', height: '80%' }}
                          >
                          </div>
                        )}
                        {documentChecked && !checkInProgress && !similarityChecked && (
                          <div id="faceFrame" className="position-absolute top-50 start-50 translate-middle rounded-circle border ${borderColor} d-flex  justify-content-center"
                            style={{ width: '30%', height: '70%' }}>

                          </div>
                        )}
                        {similarityChecked && !checkInProgress && (
                          <div id="faceFrame" className="position-absolute top-50 start-50 translate-middle rounded-circle border ${borderColor} d-flex  justify-content-center">
                          </div>
                        )}
                        {similarityChecked && documentChecked && (
                          <div id="faceFrame" className="position-absolute top-50 start-50 translate-middle rounded-circle border ${borderColor} d-flex  justify-content-center">
                          </div>
                        )}
                      </div>
                    }
                  </Col>

                  <Col sm={6} className="">
                    <Row>
                      <Col className="d-flex justify-content-center">
                        {!isMobile && startProcessButton &&
                          <Col className="d-flex justify-content-center">
                            <Button
                              id="startProcessButton"
                              className="btn-primary ms-2"
                              onClick={startProcess}
                            >
                              <i className="bi bi-camera"> </i>
                              {t("startProcess")}
                            </Button>
                          </Col>
                        }

                        {isMobile &&
                          <>
                            <input
                              type="file"
                              accept="image/*"
                              onChange={handleDocumentUpload}
                              ref={fileInputRefDocument}
                              style={{ display: 'none' }}
                            />
                            <Button
                              className="btn btn-primary w-50 me-2"
                              onClick={handleButtonDocumentUpload}
                            >
                              Upload Document Image
                            </Button>
                            <input
                              className="form-control border-primary"
                              type="file"
                              accept="image/*"
                              onChange={handleSelfUpload}
                              ref={fileInputRefSelf}
                              style={{ display: 'none' }}
                            />
                            <Button
                              className="btn btn-primary w-50 ms-2"
                              onClick={handleButtonSelfUpload}
                            >
                              Upload Self Image
                            </Button>
                          </>
                        }
                      </Col>
                    </Row>

                    <div className="mt-3">
                      <Row>
                        <img
                          id="capturedImageDocument"
                          className="w-50 object-fit-contain"
                          alt=""
                        />
                        <img
                          id="capturedImageSelf"
                          className="w-50 object-fit-contain"
                          alt=""
                        />
                      </Row>
                    </div>
                    <div ref={bottomRef}>
                      <Card
                        id="successAlert"
                        className="alert alert-success full-width mt-2"
                        hidden={successAlert}
                        role="alert"
                      >
                        <h4 className="alert-heading">{t("Well done!")} </h4>
                        <p> {t("identificationSuccessful")} </p>
                      </Card>
                      <Card
                        id="errorAlert"
                        hidden={errorAlert}
                        className="alert alert-danger mt-2"
                        role="alert"
                      >
                        {errorAlertMessage}
                        <button type="button" className="btn-close me-2 mt-2 position-absolute top-0 end-0" aria-label="Close" onClick={handleCloseAlert} />
                      </Card>
                      <Card
                        id="waitAlert"
                        className="alert alert-secondary full-width mt-2"
                        hidden={alertWait}
                        role="alert"
                      >
                        <p> {t("loading")} </p>
                      </Card>
                    </div>
                  </Col>
                </Row>
              </Container>
            </>
          ) : (
            <Alert variant="danger" className="mt-3">
              {t("accessDenied")}
            </Alert>
          )}
        </div>
      </div>
      <div className="py-2" hidden={!expiredPage}>
        <h4>{t("requestExpired")}</h4>
        <p>{t("accessExpired")}</p>
      </div>
    </>
  );
}

export default Index;