import de from './de'
import ch from './ch'

const dictionary = process.env.REACT_APP_LOCALE === 'ch' ? { ...de, ...ch } : de as any

const locales = {
  'de': 'Deutsch',
  'en-GB': 'English',
  'fr': 'Français',
  'it': 'Italiana',
  'es': 'Español',
  'pt-PT': 'Português',
  'nl': 'Dutch',
  'da': 'Nederlands',
  'fi': 'Suomalainen',
  'el': 'Ελληνικά',
  'pl': 'Polski',
  'nb': 'norsk',
  'sv': 'svenska',
  'cs': 'čeština',
  'sk': 'slovenský',
  'sl': 'Slovenščina',
  'hu': 'Magyar',
  'ro': 'Română',
  'bg': 'български',
  'et': 'eesti keel',
  'lt': 'lietuvių',
  'lv': 'latviski',
}

function setDefaults() {
  localStorage.setItem('locale', 'de')
  localStorage.setItem('dictionary', JSON.stringify(dictionary))
}

function changeLocale(locale: string) {

  if (locale === 'de') {
    setDefaults()
    window.location.reload()
    return
  }

  fetch(`${process.env.REACT_APP_API_URL}/deepl/translate/${locale}`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(dictionary),
  }).then(r => r.json())
    .then(r => {
      if (!!r && !r.err) {
        localStorage.setItem('locale', locale)
        localStorage.setItem('dictionary', JSON.stringify(r))
        window.location.reload()
      }
    })
}

function getLocale() {
  const locale = localStorage.getItem('locale') ?? 'de'

  if (locale === 'de') {
    setDefaults()
  }

  window.dictionary = JSON.parse(localStorage.getItem('dictionary') as string)

  return locale
}

function t(key: string) {
  return window.dictionary[key] ?? key
}

export { locales, changeLocale, getLocale, t }
