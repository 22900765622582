import { Button } from 'react-bootstrap'
import Alert from 'react-bootstrap/Alert'

function KAlert(props: any) {

    const message = props.message

    return (
        <Alert show={props.show ?? false}
            variant={props.variant}
            className='py-2'>

            {
                props.dissmisable &&
                <Button variant='transparent'
                    className='btn-close f-right'
                    onClick={() => { window.dispatchEvent(new Event('hideKAlert')) }} />
            }

            {
                Array.isArray(message) ?
                    message.map(
                        (msg: string, key: number) => <div key={key}>{msg}</div>
                    )
                    : message
            }
        </Alert>
    )
}

export default KAlert
