import { useCallback, useState, useEffect, useRef } from "react";
import { Alert, Button, Card, Col, Container, Row } from "react-bootstrap";
import { loadStripe } from "@stripe/stripe-js";
import { EmbeddedCheckoutProvider, EmbeddedCheckout, } from "@stripe/react-stripe-js";
import { Navigate } from "react-router-dom";

import { t } from "../locale";
import { captureSnapshot, compare, CameraComponent, createJob } from "../helpers/helpers";

let capturedImagesCount = 0;
let selfImageData: string;
let documentImageData: string;

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC || "");

export const CheckoutForm = () => {

  const [expiredPage, setExpiredPage] = useState(false);
  const fetchClientSecret = useCallback(async () => {
    const pathSegments = window.location.pathname.split("/");
    const companyId = pathSegments[1];
    const requestId = pathSegments[2];

    let email;
    const requestData = await fetch(
      `${process.env.REACT_APP_API_URL}/api/v1/request/data`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          companyId: companyId,
          requestId: requestId,
        }),
      }
    )
      .then(async (res) => {
        return [await res.json()];
      })
      .then(([jsonData]) => {
        email = jsonData.email;
        if (jsonData.status === "EXPIRED") {
          setExpiredPage(true);
        }
      });

    // create a checkout session
    return fetch(
      `${process.env.REACT_APP_API_URL}/api/v1/sessions/create-checkout-session`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          companyId: companyId,
          requestId: requestId,
          customerEmail: email,
        }),
      }
    )
      .then((res) => res.json())
      .then((data) => data.clientSecret);
  }, []);

  const options = { fetchClientSecret };

  return (
    <Container>
      <div className="section-container">
        <div
          className="section"
          style={{ display: expiredPage ? "none" : "block" }}
        >
          <div id="checkout">
            <EmbeddedCheckoutProvider stripe={stripePromise} options={options}>
              <EmbeddedCheckout />
            </EmbeddedCheckoutProvider>
          </div>
        </div>

        <div
          className="section"
          style={{ display: expiredPage ? "block" : "none" }}
        >
          <div className="expired-notification-frame">
            <h1>Request Expired</h1>
            <p>
              We regret to inform you that your identification request
              has expired. This means you will need to initiate a new
              request to proceed.
            </p>
          </div>
        </div>
      </div>
    </Container>
  );
};

export function useSelfIdentification() {
  const [showSelfIdentification, setShowSelfIdentification] = useState(true);

  async function toggleSelfIdentification() {
    setShowSelfIdentification((prevState) => !prevState);
  }

  return { showSelfIdentification, toggleSelfIdentification };
}

export const Return = () => {


    const [status, setStatus] = useState(null);
    const [customerEmail, setCustomerEmail] = useState("");
    const { showSelfIdentification, toggleSelfIdentification } = useSelfIdentification();
    const [hasAccess, setHasAccess] = useState(true);
    const [expiredPage, setExpiredPage] = useState(false);
    const [facingMode, setFacingMode] = useState('environment');
    const [referenceNumber, setReferenceNumber] = useState(null);
    const [isVisible, setIsVisible] = useState(false);
    const [successAlert, setSuccessAlert] = useState(true)
    const [errorAlert, setErrorAlert] = useState(true);
    const [documentCaptured, setDocumentCaptured] = useState(true);
    const [selfCaptured, setSelfCaptured] = useState(true);
    const [isMobile, setIsMobile] = useState(true);
    const bottomRef = useRef<HTMLDivElement>(null);
  const [documentButtonClicked, setDocumentButtonClicked] = useState(false);
  const [selfButtonClicked, setSelfButtonClicked] = useState(false);

  const handleButtonClick = (button:string) => {
    if (button === 'doc') {
      setDocumentButtonClicked(true);
    } else if (button === 'self') {
      setSelfButtonClicked(true);
    }
  };

  const captureSelfButton = document.getElementById("captureSelfButton") as HTMLButtonElement;
  const captureDocumentButton = document.getElementById("captureDocumentButton") as HTMLButtonElement;

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const sessionId = urlParams.get("session_id");
    fetchData();
    handleDeviceDetection();

    fetch(
      `${process.env.REACT_APP_API_URL}/api/v1/sessions/session-status?session_id=${sessionId}`
    )
      .then((res) => res.json())
      .then((data) => {
        setStatus(data.status);
        setCustomerEmail(data.customer_email);
      });
  }, []);

  const scrollToDiv = () => {
    if (bottomRef.current) {
      bottomRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const fetchData = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/request/data/all`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            companyId: companyId,
            requestId: requestId,
          }),
        }
      );
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const data = await response.json();

      if (data) {
        if (data.status === "EXPIRED") {
          setExpiredPage(true);
        }
      }

    } catch (error) {
      console.error("Error fetching data:", error);

      setHasAccess(false)
    }
  };

    function scrollToBottom() {
      // Use setTimeout to allow DOM updates before scrolling
      setTimeout(() => {
        window.scrollTo(0, document.body.scrollHeight);
      }, 0);
    }

    const handleDeviceDetection = () => {
      const userAgent = navigator.userAgent.toLowerCase();
      const isMobile = /iphone|ipad|ipod|android|blackberry|windows phone/g.test(userAgent);
      const isTablet = /(ipad|tablet|playbook|silk)|(android(?!.*mobile))/g.test(userAgent);
      if (isMobile) {
        setIsMobile(false);
      } else if (isTablet) {
        setIsMobile(false)
      }
    }
    const handleToggleCamera = () => {
        setFacingMode(prevMode => (prevMode === 'user' ? 'environment' : 'user'));
    };
    CameraComponent(facingMode, isMobile)

    const handleCaptureDocument = async () => {
      handleButtonClick('doc');
        const video = document.getElementById('videoElement') as HTMLVideoElement;
        const result = await captureSnapshot(video, 'DOCUMENT', selfImageData, documentImageData);
        selfImageData = result.selfImageData;
        documentImageData = result.documentImageData;

        // video.pause();
        const capturedBase64Image = documentImageData;
        const capturedImageElement = document.getElementById('capturedImageDocument') as HTMLImageElement;
        capturedImageElement.src = "data:image/png;base64," + capturedBase64Image;
        setDocumentCaptured(false);

        if (capturedImagesCount === 2) {
          capturedImagesCount = 2;
          setIsVisible(true);
          scrollToBottom();
        }
      }

      const handleCaptureSelf = async () => {

        handleButtonClick('self');
        const video = document.getElementById('videoElement') as HTMLVideoElement;
        const result = await captureSnapshot(video, 'SELF', selfImageData, documentImageData);
        selfImageData = result.selfImageData;
        documentImageData = result.documentImageData;

        const capturedBase64Image = selfImageData;
        const capturedImageElement = document.getElementById('capturedImageSelf') as HTMLImageElement;
        capturedImageElement.src = "data:image/png;base64," + capturedBase64Image;
        setSelfCaptured(false)

        if (documentButtonClicked && selfButtonClicked) {

          setIsVisible(true);
          scrollToBottom();
        }
      }

      const continueProcess = async () => {

        if (documentButtonClicked && selfButtonClicked) {
          const continueButton = document.getElementById('continue') as HTMLButtonElement;
          continueButton.disabled = true;
          captureDocumentButton.disabled = true;
          captureSelfButton.disabled = true;
          const compareResult = await compare(selfImageData, documentImageData);

          const data = await checkRequestSimilarity(companyId, requestId)
          if (compareResult['status'] === 'OK') {
            setTimeout(() => {
              setSuccessAlert(false);
            }, 3000);

            toggleSelfIdentification();
            createJob(companyId, requestId, setReferenceNumber);
          } else {
            continueButton.disabled = false;
            captureDocumentButton.disabled = false;
            captureSelfButton.disabled = false;
            setErrorAlert(false);
            setTimeout(() => {
              setErrorAlert(true);
            }, 3000);
          }
          scrollToBottom();
        }
      }

  if (status === "open") {
    return <Navigate to="/checkout" />;
  }

  const pathSegments = window.location.pathname.split("/");
  const companyId = pathSegments[1];
  const requestId = pathSegments[2];

  if (status === "complete") {
    return (
      <>
        <div hidden={expiredPage}>
          <div hidden={!showSelfIdentification}>
            <Card className="mb-2 p-1">
              <Container>
                <h4 style={{ color: "#007bff" }}>
                  {!showSelfIdentification
                    ? t("selfIdentifactionStep")
                    : t("paymentStep")}
                </h4>
              </Container>
            </Card>

            {hasAccess ? (
              <>
                <Card className="mb-2">
                  <Container>
                    <div className="lighting-instructions p-3">
                      <h5 style={{ color: "#007bff" }}>{t("tipsTitle")}</h5>
                      <ul>
                        {/* <li>{t("tipSentence1")}</li>
                          <li>{t("tipSentence2")}</li>
                          <li>{t("tipSentence3")}</li>
                          <li>{t("tipSentence4")}</li>
                          <li>{t("tipSentence5")}</li>
                          <li>{t("tipSentence6")}</li>
                          <li>{t("captureDocumentText")}</li>
                          <li>{t("captureSelfText")}</li> */}
                        <li>Vazni saveti za uspesnu video identifikaciju:</li>
                        <li>Uverite se da je prostor dovoljno osvetljen kako biste napravili jasne fotografije vaseg lica i dokumenta</li>
                        <li>Izbegavajte pozadinsko osvetljenje, svetlo bi trebalo da se nalazi ispred vas</li>
                        <li>Priblizite dokument kameri i sacekajte dok se kamera fokusira pre snimanja fotografije</li>
                        <li>Kliknite na 'Dokument' da biste snimili fotografiju dokumenta</li>
                        <li>Kliknite na 'Gesicht' da biste snimili fotografiju sebe</li>
                        <li>Na desnoj strani pregledajte fotografije i kada ste zadovoljni kvalitetom slika kliknite na 'Weitermachen'</li>
                        <li>Sacekajte da se obrade fotografije i da dobijete poruku na ekranu</li>
                        <li>Ako identifikacija nije uspela probajte ponovo</li>
                      </ul>
                    </div>
                  </Container>
                </Card>
                <Container className="mb-5 border rounded">
                  <Row>
                    <Col sm={6} className="pl-2 pt-2">
                      <Button
                        variant="secondary"
                        onClick={handleToggleCamera}
                        hidden={isMobile}
                        className="toggle-camera-btn my-2"
                        size="sm"
                      >
                        <i className="bi bi-arrow-repeat"> </i>
                        Switch Camera
                      </Button>
                      <video className="rounded w-100" id="videoElement"></video>
                    </Col>

                    <Col sm={6} className="">
                      <Row>
                        <Col className="d-flex justify-content-center">
                          <Button
                            id="captureDocumentButton"
                            className="btn-info mt-2"
                            onClick={handleCaptureDocument}
                            size="sm"
                          >
                            {" "}
                            <i className="bi bi-camera"> </i>
                            {t("captureDocumentButton")}
                          </Button>
                        </Col>
                        <Col className="d-flex justify-content-center">
                          <Button
                            id="captureSelfButton"
                            className="btn-primary mt-2"
                            onClick={handleCaptureSelf}
                          >
                            <i className="bi bi-camera"> </i>
                            {t("captureSelfButton")}
                          </Button>
                        </Col>
                      </Row>

                      <div className="mt-3">
                        <Row>
                          <img
                            id="capturedImageDocument"
                            className="w-50"
                            alt="Captured Document"
                            hidden={documentCaptured}
                          />
                          <img
                            id="capturedImageSelf"
                            className="w-50"
                            alt="Captured Self"
                            hidden={selfCaptured}
                          />
                        </Row>
                      </div>
                      <div ref={bottomRef}>
                        <Card
                          id="successAlert"
                          className="alert alert-success full-width mt-2"
                          hidden={successAlert}
                          role="alert"
                        >
                          <h4 className="alert-heading">{t("Well done!")} </h4>
                          <p> {t("identificationSuccessful")} </p>
                        </Card>
                        <Card
                          id="errorAlert"
                          hidden={errorAlert}
                          className="alert alert-danger mt-2"
                          role="alert"
                        >
                          {t("identificationFailed")}
                        </Card>
                      </div>

                      {isVisible && (
                        <Button
                          id="continue"
                          className="btn my-3 btn-success"
                          onClick={continueProcess}
                        >
                          {" "}
                          <i className="bi bi-box-arrow-right"> </i>
                          {t("continue")}
                        </Button>
                      )}
                    </Col>
                  </Row>
                </Container>
              </>
            ) : (
              <Container>
                <Alert variant="danger">
                  <div>{t("accessDenied")}</div>
                </Alert>
              </Container>
            )}
          </div>

          <div id="paymentInstructions" hidden={!showSelfIdentification}>
            <Card className="p-2">
              <Container>
                <h4 style={{ color: "#007bff" }}>
                  {showSelfIdentification
                    ? t("selfIdentification")
                    : t("payment")}
                </h4>
              </Container>
            </Card>
            {hasAccess ? (
              <Card className="p-2 my-4">
                <Container>
                  <h4 style={{ color: "#007bff" }}>{t("paymentInstructions")}</h4>
                  <ul>
                    {/* <li>{t("ebanking")}</li> */}
                    {/* <li>{t("accountNumber")} DE85 7115 0000 00202133 77</li> */}
                    {/* <li> */}
                    {/* {t("referenceNumber")}: {referenceNumber} */}
                    {/* </li> */}
                    <li>Da biste zavrsili identifikaciju potrebno je da izvrsite uplatu od 0.50 koristeci vasu e-banking aplikaciju,
                      kako bi uporedili vase podatke.
                    </li>
                    <li>Uplatu mozete izvrsiti na racun: DE85 7115 0000 00202133 77</li>
                    <li>VAZNA NAPOMENA: Prilikom uplate u polju 'description' unesite samo sledeci kod: <h4>{referenceNumber}</h4></li>
                    <li>Nakon sto ste uneli podatk u prvom koraku, imate 7 dana da izvrsite uplatu, nakon toga identifikacija ce biti nevazeca.</li>
                  </ul>
                </Container>
              </Card>
            ) : (
              <Alert variant="danger">
                <div>{t("accessDenied")}</div>
              </Alert>
            )}
          </div>
        </div>

        <Card className="border-0 expired-notification-frame">
          <div hidden={!expiredPage}>
            <h2>{t("requestExpired")}</h2>
            <p>{t("accessExpired")}</p>
          </div>
        </Card>
      </>
    );
  }
  return null;
}

async function checkRequestSimilarity(companyId: string, requestId: string) {
  try {
    const response = await fetch(
      process.env.REACT_APP_API_URL + "/api/v1/request/data",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ requestId, companyId }),
      }
    );

    if (response.ok) {
      const data = await response.json();
      return data;
    }
  } catch (error) {
    // Handle any errors, such as network issues or server errors
    console.error("Error while checking request:", error);
    return false;
  }
}
