import { Alert, Button, Card, Col, Container, Row } from "react-bootstrap";
import { useRef, useState, useEffect } from "react";
import { t } from "../locale";
import {
    captureSnapshot,
    CameraComponent,
    saveImage,
    saveVideo
} from "../helpers/helpers";
import 'bootstrap-icons/font/bootstrap-icons.css';
import { useNavigate } from "react-router-dom";

let selfImageData: string;
let documentImageData: string;

function Index() {
    const [selfIdentify, setSelfIdentify] = useState(true);
    const [hasAccess, setHasAccess] = useState(true);
    const [expiredPage, setExpiredPage] = useState(false);
    const [facingMode, setFacingMode] = useState('environment');
    const [successAlert, setSuccessAlert] = useState(true);
    const [errorAlert, setErrorAlert] = useState(true);
    const [isMobile, setIsMobile] = useState(false);
    const [borderColor, setBorderColor] = useState('border-light'); // Initial gray color
    const [secondsLeft, setSecondsLeft] = useState(10); // Initialize with 25 seconds
    const [textLabel, setTextLabel] = useState(t('holdDocument'));
    const [checkInProgress, setCekInProgress] = useState(true);
    const [buttonVisible, setButtonVisible] = useState(true);
    const [buttonRecordingVisible, setButtonRecordingVisible] = useState(false);
    const [buttonStopRecordingVisible, setButtonStopRecordingVisible] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const fileInputRefDocument = useRef<HTMLInputElement | null>(null);
    const fileInputRefVideo = useRef<HTMLInputElement | null>(null);
    const navigate = useNavigate();

    const bottomRef = useRef<HTMLDivElement>(null);

    const pathSegments = window.location.pathname.split("/");
    const companyId = pathSegments[1];
    const requestId = pathSegments[2];

    const handleDeviceDetection = () => {
        const userAgent = navigator.userAgent.toLowerCase();
        const isMobile = /iphone|ipad|ipod|android|blackberry|windows phone/g.test(userAgent);
        const isTablet = /(ipad|tablet|playbook|silk)|(android(?!.*mobile))/g.test(userAgent);

        if (isMobile) {
            setIsMobile(true);
        } else if (isTablet) {
            setIsMobile(true)
        }
    }

    const handleButtonVideoUpload = () => {
        if (fileInputRefVideo.current) {
            fileInputRefVideo.current.click(); // Trigger the file input click
        }
    };

    const handleVideoUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0]; // Check if a file is selected
        if (file) {
            // Ensure the file is a video by checking its type
            if (file.type.startsWith('video/')) {
                // `file` is already a Blob, no need to convert it to base64
                const videoBlob = new Blob([file], { type: file.type });
                saveVideo(videoBlob, companyId, requestId);
                setAlertMessage('Successfully uploaded video. Wait...');

                setTimeout(() => {
                    navigate(`/${companyId}/${requestId}/si/payment/p`);
                }, 5000)

            } else {
                console.error('Please upload a valid video file');
            }
        }
    };

    const handleButtonDocumentUpload = () => {
        if (fileInputRefDocument.current) {
            fileInputRefDocument.current.click(); // Trigger the file input click
        }
    };


    const handleDocumentUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0]; // Check if a file is selected
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                const base64String = reader.result as string;
                if (base64String.includes('data:image/png;base64')) {
                    documentImageData = base64String.replace(/^data:image\/png;base64,/, '');
                } else if (base64String.includes('data:image/jpeg;base64')) {
                    documentImageData = base64String.replace(/^data:image\/jpeg;base64,/, '');
                }

                const capturedDocImageElement = document.getElementById("capturedImageDocument") as HTMLImageElement;
                if (capturedDocImageElement) {
                    capturedDocImageElement.src = base64String; // Set src for document image
                    capturedDocImageElement.style.height = '200px';
                }
                saveImage(documentImageData, companyId, requestId);
            };

            reader.readAsDataURL(file); // Convert image to Base64
            setButtonRecordingVisible(true);
            setButtonVisible(false);
            setSuccessAlert(false);
            setAlertMessage('Successfully uploaded document image');

        }
    };



    CameraComponent(facingMode, isMobile);

    const handleCloseAlert = () => {
        setErrorAlert(true);
    };

    function scrollToBottom() {
        setTimeout(() => {
            window.scrollTo(0, document.body.scrollHeight);
        }, 0);
    }

    useEffect(() => {
        fetchData();
        handleDeviceDetection();

    }, []);

    const fetchData = async () => {
        try {
            const response = await fetch(
                `${process.env.REACT_APP_API_URL}/api/v1/request/data/all`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        companyId: companyId,
                        requestId: requestId,
                    }),
                }
            );
            if (!response.ok) {
                throw new Error("Failed to fetch data");
            }

            const data = await response.json();

            if (data) {
                if (data.status === "EXPIRED") {
                    setExpiredPage(false);
                }
            }
        } catch (error) {
            console.error("Error fetching data:", error);
            setHasAccess(false);
        }
    };

    const init = () => {
        setFacingMode('environment')
        // setCekInProgress(false);
        setTextLabel(t('holdDocument'));
        setSecondsLeft(10);

    }

    const startCapture = async () => {
        const video = document.getElementById("videoElement") as HTMLVideoElement;
        let capturedDocImageElement: HTMLImageElement | null = null;

        setButtonVisible(false);

        const countdown = setInterval(() => {
            setSecondsLeft(prevSeconds => {
                if (prevSeconds <= 1) {
                    clearInterval(countdown); // Change border color to green when countdown ends
                    return 0;
                }
                return prevSeconds - 1;
            });
        }, 1000);

        setTimeout(async () => {
            const result = await captureSnapshot(
                video,
                "DOCUMENT",
                selfImageData,
                documentImageData
            );

            saveImage(result.documentImageData, companyId, requestId);
            documentImageData = result.documentImageData; // Ensure documentImageData is updated
            if (!capturedDocImageElement) {
                capturedDocImageElement = document.getElementById("capturedImageDocument") as HTMLImageElement;
            }
            if (capturedDocImageElement) {
                capturedDocImageElement.src = "data:image/png;base64," + documentImageData;
            }

            setBorderColor('border-success');
            setTextLabel('Finished. Start Recording when you ready.');

            setButtonRecordingVisible(true);

        }, 10000);
    }

    const [recording, setRecording] = useState(false);
    const [videoURL, setVideoURL] = useState<string | null>(null);

    const videoRef = useRef<HTMLVideoElement | null>(null);
    const mediaRecorderRef = useRef<MediaRecorder | null>(null);
    const recordedChunks = useRef<Blob[]>([]);

    const startRecording = async () => {
        try {
            setTextLabel('Recording...');
            setButtonRecordingVisible(false);
            setButtonStopRecordingVisible(true);
            setCekInProgress(false);
            const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });

            if (videoRef.current) {
                videoRef.current.srcObject = stream;
            }

            const mediaRecorder = new MediaRecorder(stream, { mimeType: "video/webm" });
            mediaRecorderRef.current = mediaRecorder;

            mediaRecorder.ondataavailable = (event) => {
                if (event.data.size > 0) {
                    recordedChunks.current.push(event.data);
                }
            };

            mediaRecorder.onstop = () => {
                setTextLabel('Recording finished.')
                const blob = new Blob(recordedChunks.current, { type: "video/webm" });
                if (blob.size > 0) {
                    const url = URL.createObjectURL(blob);
                    setVideoURL(url);
                    saveVideo(blob, companyId, requestId);

                    setTextLabel('Wait...');
                    setTimeout(() => {
                        navigate(`/${companyId}/${requestId}/si/payment/p`);
                    }, 5000)
                } else {
                    alert("Video file is empty. Please try again.");
                }
                recordedChunks.current = [];
            };

            mediaRecorder.start();
            setRecording(true);
        } catch (error) {
            console.error("Error starting video recording:", error);
        }
    };

    const stopRecording = () => {
        setButtonStopRecordingVisible(false);
        setButtonRecordingVisible(true);
        if (mediaRecorderRef.current) {
            mediaRecorderRef.current.stop();
        }

        if (videoRef.current && videoRef.current.srcObject) {
            const stream = videoRef.current.srcObject as MediaStream;
            stream.getTracks().forEach((track) => track.stop());
        }

        setRecording(false);
    };


    return (
        <>
            <div hidden={expiredPage}>
                <div hidden={!selfIdentify}>
                    {hasAccess ? (
                        <>
                            <h4 className="my-3 text-primary">
                                {selfIdentify
                                    ? t("selfIdentifactionStep")
                                    : t("paymentStep")}
                            </h4>
                            <Container>
                                <div className="mt-2"></div>
                                {!isMobile &&
                                    <ul>
                                        <li>{t("videoStep")}</li>
                                        <li>{t("videoStep1")}</li>
                                        <li>{t("videoStep2")}</li>
                                    </ul>
                                }
                                {isMobile && 
                                    <ul>

                                    <li>{t("videoStepMobile")}</li>
                                    <li>{t("videoStepMobile1")}</li>
                                    <li>{t("videoStepMobile2")}</li>
                                </ul>
                                }
                            </Container>
                            <Container className="mb-5">
                                <Row>
                                    {!isMobile &&

                                        <Col sm={6} className="pl-2 pt-2">
                                            <div id="videoContainer" className="position-relative">
                                                <video ref={videoRef} className="position-relative rounded w-100" id="videoElement" autoPlay muted playsInline></video>
                                                <div className="position-absolute top-0 start-50 translate-middle-x d-flex justify-content-center text-white mt-2">
                                                    {!isMobile && <span className="me-2 text-nowrap">{textLabel}</span>}
                                                    {isMobile && <span className="me-2 small text-nowrap">{textLabel}</span>}
                                                    {secondsLeft !== 0 && <span>{secondsLeft}</span>}
                                                </div>
                                                {checkInProgress && (
                                                    <div
                                                        id="documentFrame"
                                                        className={`position-absolute top-50 start-50 translate-middle rounded-3 border ${borderColor} d-flex justify-content-center`}
                                                        style={{ width: '75%', height: '80%' }}
                                                    >
                                                    </div>
                                                )}
                                            </div>
                                        </Col>
                                    }
                                    <Col sm={6} className="">
                                        {!isMobile &&
                                            <Row>
                                                <Col className="d-flex justify-content-center">
                                                    {buttonVisible && <Button
                                                        id="startProcessButton"
                                                        className="btn-primary"
                                                        onClick={startCapture}
                                                    >
                                                        <i className="bi bi-camera"> </i>
                                                        {t("startProcess")}
                                                    </Button>}

                                                    {buttonRecordingVisible && <Button
                                                        id="startRecordingButton"
                                                        className="btn-primary"
                                                        onClick={startRecording}
                                                    >
                                                        <i className="bi bi-camera"> </i>
                                                        {t("Start recording")}
                                                    </Button>}

                                                    {buttonStopRecordingVisible && <Button
                                                        id="startRecordingButton"
                                                        className="btn-primary"
                                                        onClick={stopRecording}
                                                    >
                                                        <i className="bi bi-camera"> </i>
                                                        {t("Stop recording")}
                                                    </Button>}
                                                </Col>
                                            </Row>
                                        }
                                        {isMobile &&
                                            <Col className="d-flex justify-content-center">
                                                {buttonVisible &&
                                                    <>
                                                        <input
                                                            type="file"
                                                            accept="image/*"
                                                            onChange={handleDocumentUpload}
                                                            ref={fileInputRefDocument}
                                                            style={{ display: 'none' }}
                                                        />
                                                        <Button
                                                            id="uploadImageBack"
                                                            className="btn-primary"
                                                            onClick={handleButtonDocumentUpload}
                                                        >
                                                            <i className="bi bi-camera"> </i>
                                                            {t("uploadImageBack")}
                                                        </Button>
                                                    </>}

                                                {buttonRecordingVisible &&
                                                    <>
                                                        <input
                                                            type="file"
                                                            accept="video/*"
                                                            onChange={handleVideoUpload}
                                                            ref={fileInputRefVideo}
                                                            style={{ display: 'none' }}
                                                        />
                                                        <Button
                                                            id="uploadVideo"
                                                            className="btn-primary"
                                                            onClick={handleButtonVideoUpload}
                                                        >
                                                            <i className="bi bi-camera"> </i>
                                                            {t("uploadVideo")}
                                                        </Button>
                                                    </>}

                                            </Col>
                                        }

                                        <div className="mt-3">
                                            <Row>
                                                <img
                                                    id="capturedImageDocument"
                                                    className="rounded w-50"
                                                    alt=""
                                                />
                                            </Row>
                                        </div>
                                        <div ref={bottomRef}>
                                            <Card
                                                id="successAlert"
                                                className="alert alert-success full-width mt-2"
                                                hidden={successAlert}
                                                role="alert"
                                            >
                                                <h4 className="alert-heading">{t("Well done!")} </h4>
                                                <p> {alertMessage} </p>
                                            </Card>
                                            <Card
                                                id="errorAlert"
                                                hidden={errorAlert}
                                                className="alert alert-danger mt-2"
                                                role="alert"
                                            >
                                                {t("identificationFailed")}
                                                <button type="button" className="btn-close me-2 mt-2 position-absolute top-0 end-0" aria-label="Close" onClick={handleCloseAlert} />
                                            </Card>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </>
                    ) : (
                        <Alert variant="danger" className="mt-3">
                            {t("accessDenied")}
                        </Alert>
                    )}
                </div>

            </div>
            <div className="py-2" hidden={!expiredPage}>
                <h4>{t("requestExpired")}</h4>
                <p>{t("accessExpired")}</p>
            </div>
        </>
    );
}

export default Index;