import React from 'react';
import { t } from "../locale";

interface NationalityDropdownProps {
  selectedNationality: string;
  onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
}

const NationalityDropdown: React.FC<NationalityDropdownProps> = ({ selectedNationality, onChange }) => {
  const nationalities = [
    { country: t('Germany'), nationality: "deutsch" },
    { country: t('Switzerland'), nationality: "che" },
    { country: t('Austria'), nationality: "aut" },
    { country: t('France'), nationality: "fra" },
    { country: t('Italy'), nationality: "ita" }
  ];

  return (
    <select
      id="nationality"
      className="form-control"
      value={selectedNationality}
      onChange={onChange}
      required={true}
    >
      <option value="">Select a nationality</option>
      {nationalities.map((item, index) => (
        <option key={index} value={item.nationality}>
          {item.country}
        </option>
      ))}
    </select>
  );
};

export default NationalityDropdown;